import { createContext, useContext } from "react";
import { useLocalStorage } from "./localstorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);

  // call this function when you want to authenticate the user
  const login = (data) => {
    setUser(data);
  };

  return <AuthContext.Provider value={{
    user,
    login,
  }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};