import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Header from './components/Header';
// import Messaggi from './pages/Messaggi'
// import Lista from './pages/Lista'
import Login from './pages/Login'
// import RSVP from './pages/RSVP'
// import Cerimonia from './pages/Cerimonia'
// import InvitatiPage from './pages/Invitati'
import Upload from './pages/Upload'

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { CookiesProvider } from 'react-cookie'
import { useAuth, AuthProvider } from "./auth/auth";


const theme = createTheme({
  palette: {
    primary: {
      //main: "#fd69a0"
      main: "#f3dee0"
      //"#FFBFD6"
      //#FFBFD6
    },
    secondary: {
      main: "#f50057"
    }
  }
});

// function CompleteRSVP(props) {
//   const { cb } = props;
//   cb(true);
//   return (
//     <Navigate to="/rsvp?completed=true" />
//   )
// }

function App() {

  // const [cookies, setCookie] = useCookies(['completed'])

  // function handleCookies(v) {
  //   setCookie('completed', v, { path: '/' })
  // }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CookiesProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Header />}>
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<ProtectedRoute><Upload /></ProtectedRoute>} />
                <Route index element={<ProtectedRoute><Upload /></ProtectedRoute>} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CookiesProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to={"/login?redirect=" + window.location.pathname} />;
  }
  return children;
};

// const InvitatiRoute = ({ children }) => {
//   const user = localStorage.getItem("giorgia");
//   if (user !== "mylove") {
//     // user is not authenticated
//     return <Navigate to="/" />;
//   }
//   return children;
// };


export default App;
