import * as React from 'react';
import { Navigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useAuth } from '../auth/auth';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function SignIn() {
  const [password, setPassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();

  const { user, login } = useAuth();

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    // Display loading
    if (!loading) {
      setLoading(true);
      timer.current = setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    if(password === "31agosto") {
      login({ "user": "loggedin" });
      setLoading(false);
      window.location.href = '/';
    }
  }

  if (user) {
    const urlParams = new URLSearchParams(window.location.search);
    const redir = urlParams.get('redirect');
    if(redir) {
      window.location.href = redir;
      return <Navigate to={redir} />;
    }
    window.location.href = '/';
  }

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{paddingTop: "20px"}} alignItems="center">
      <Grid item xs={0} md={4}></Grid>
      <Grid item xs={12} md={4}>
        <Item>
          <Typography component='h1' variant='h5'>
            Inserisci la password
          </Typography>
          <form
            noValidate
            onSubmit={(event) => handleSubmit(event)}
          >
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              //value={this.state.password}
              onChange={(event) => setPassword(event.target.value)}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              disabled={loading}
            >
              {!loading && "Accedi"}
              {loading && <CircularProgress size={24} />}
            </Button>
          </form>
        </Item>
      </Grid>
    </Grid>
  );
}