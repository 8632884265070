import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Modal from "@mui/material/Modal"
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Pagination from '@mui/material/Pagination';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Upload(props) {
  const imgPerPage = 10;
  const [itemData, setItemData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState("false");

  const [totPages, setTotPages] = React.useState(0);
  const [page, setPage] = React.useState(1);

  const isLocalDev = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
  //const baseUrl = isLocalDev ? "http://localhost:8000/" : "/";
  const baseUrl = isLocalDev ? "http://192.168.1.195:8000/" : "https://www.giorgiaegiovanni.it/";


  let urlImg = baseUrl + "getimg.php"

  React.useMemo(() => {
    fetch(urlImg)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setItemData(data);
        setTotPages(Math.ceil(data.length / imgPerPage));
      })
      .catch((e) => {
        console.log(e);
      });
  }, [urlImg])



  const handleChange = (event, value) => {
    setPage(value);
  };

  // const handleFile = async (event) => {
  //   setUploading(true);
  //   const f = event.target.files;
  //   let baseImgArray = itemData;

  //   for (let i = 0; i < f.length; i++) {
  //     const file = f[i];
  //     let formData = new FormData();
  //     formData.append("files", file, file.name)
  //     try {
  //       const r = await fetch(url, {
  //         method: "POST",
  //         body: formData
  //       });
  //       const j = await r.json();
  //       if (j.code === 200) {
  //         const img = {
  //           img: j.path
  //         };
  //         const newImg = [img].concat(baseImgArray);
  //         setItemData(newImg)
  //         baseImgArray = newImg
  //       }
  //     } catch (e) {
  //       alert(e);
  //       console.log(e);
  //     }
  //   }
  //   setUploading(false);
  // }

  const handleImage = (value) => {
    setImage(value);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const s = (page - 1) * imgPerPage;
  const view = itemData.slice(s, s + imgPerPage);

  return (
    <Container maxWidth="xl" sx={{ pt: "1em" }}>
      <Pagination count={totPages} page={page} color="secondary" size="large" onChange={handleChange} sx={{
        padding: "10px",
        width: "100%",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
      }} />
      <Box sx={{ width: '100%' }}>
        <Stack spacing={0}>
          <Item>
            <Button
              component="label"
              role="link"
              variant="contained"
              color='secondary'
              tabIndex={-1}
              startIcon={<DownloadIcon />}
              sx={{ marginBottom: "10px" }}
              onClick={() => {
                window.open(`${baseUrl}giorgia_giovanni.zip`, '_blank').focus();
              }}
            >
              Download All
            </Button>

            <Box sx={{ width: "100%", overflowY: 'scroll', paddingTop: "0px" }}>
              <ImageList variant="masonry" cols={3} gap={8}>

                {view.map((item, i) => (
                  <ImageListItem key={i}>
                    <img onClick={() => handleImage(`${item.src}`)}
                      srcSet={`${baseUrl}thumbnail/${item.src}`}
                      src={`${baseUrl}thumbnail/${item.src}`}
                      alt={item.src}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
              <Modal
                sx={{
                  display: 'flex',
                  alignItems: "center",
                  justifyContent: "center",
                }}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                disablePortal={true}
              >
                <div style={{
                  display: 'flex',
                  //alignItems: "center",
                  justifyContent: "center",
                  maxHeight: "90%",
                  maxWidth: "90%",
                  position: "relative"
                }}>
                  <img
                    src={`${baseUrl}foto/${image}`}
                    alt="asd"
                    style={{ maxHeight: "100%", maxWidth: "100%", objectFit: 'scale-down' }}
                  />
                  <div style={{
                    position: "absolute",
                    bottom: '0px',
                    background: 'rgba(0, 0, 0, 0.5)',
                    // width: "100%",
                    minHeight: "40px",
                    color: "#fff"
                  }}>
                    <IconButton
                      href={`${baseUrl}download.php?pic=${image}`}
                      sx={{ color: 'rgba(255, 255, 255, 255)' }}
                    >
                      Download <DownloadIcon />
                    </IconButton>
                  </div>
                </div>
              </Modal>


            </Box>
          </Item>
        </Stack>
      </Box>

      <Pagination count={totPages} page={page} color="secondary" size="large" onChange={handleChange} sx={{
        padding: "10px",
        width: "100%",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
      }} />
    </Container >

  );

}

export default Upload;